import React from 'react';
import { SKY_BROADBAND_T_AND_C_URL, TERMS_AND_CONDITIONS_DOMESTIC, SKY_TV_T_AND_C_URL } from '../config';
import { links } from '@sky-tv-group/shared';

interface TermsAndConditionsProps {
  isActiveSkyStarter: boolean;
  containerClassName?: string;
  fontClass?: string;
}

const BroadbandTerms = ({ isActiveSkyStarter, containerClassName, fontClass }: TermsAndConditionsProps) => {
  return (
    <>
      <div className={containerClassName}>
        <p className={fontClass}>
        Fibre Pro and Fibre Everyday speeds shown are based on our network provider's average peak time speeds shown in the Commerce Commission MBNZ Report 22, December 2024. Your speeds may vary especially over WiFi. Factors that can affect your speed include the time of day, where you live, the location and quality of your router and devices and the number of devices being used.
          <a
            className="text-blue-light underline"
            href="https://help.sky.co.nz/s/article/How-fast-is-it-really"
            target="_blank"
            rel="noopener noreferrer">
            &nbsp;Learn more.
          </a>
        </p>
      </div>
      {isActiveSkyStarter ? (
        <div className={' m-auto flex flex-col items-center justify-center  pt-4 pb-8 ' + fontClass}>
          <div className="pb-2">
            Sky Broadband on a <strong>rolling monthly plan</strong>.
          </div>
          <div>
            <a href={TERMS_AND_CONDITIONS_DOMESTIC} className="text-blue-light underline">
              Consumer Terms
            </a>
            ,{' '}
            <a href={SKY_BROADBAND_T_AND_C_URL} className="text-blue-light underline">
              Broadband Terms
            </a>{' '}
            and{' '}
            <a href={links.bbExitFees} className="text-blue-light underline">
              Exit Fees
            </a>{' '}
            apply to all plans.
          </div>
        </div>
      ) : (
        <div className={' m-auto flex flex-col items-center justify-center  pt-4 pb-8 ' + fontClass}>
          <p style={{ textIndent: '1.5rem' }} className="pb-2">
            Join Sky TV on a <strong>12 month contract</strong> and get Sky Broadband on{' '}
            <strong>a rolling monthly plan</strong>. Only want Sky Broadband? Plans available on a{' '}
            <strong>12 month contract</strong>.
          </p>
          <div className="pb-2">
            See{' '}
            <a href={SKY_TV_T_AND_C_URL} className="text-blue-light underline">
              Sky TV Offer T&amp;Cs
            </a>{' '}
          </div>
          <div>
            <a href={TERMS_AND_CONDITIONS_DOMESTIC} className="text-blue-light underline">
              Consumer Terms
            </a>
            ,{' '}
            <a href={SKY_BROADBAND_T_AND_C_URL} className="text-blue-light underline">
              Broadband Terms
            </a>{' '}
            and{' '}
            <a href={links.bbExitFees} className="text-blue-light underline">
              Exit Fees
            </a>{' '}
            apply to all plans.
          </div>
        </div>
      )}
    </>
  );
};

export { BroadbandTerms };
